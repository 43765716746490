<template>
    <div v-bind="wrapperProps">
        <div class="media-image-view__container">
            <img :src="item.url" :alt="item.title">
        </div>
    </div>
</template>

<script>
import mediaViewMixin from '@apps/media/mixins/mediaViewMixin'

export default {
    name: 'ImageView',
    mixins: [ mediaViewMixin ],
    computed: {
        wrapperProps() {
            const classes = ['media-image-view']

            if(this.fullscreen) {
                classes.push('media-image-view--' + 'fullscreen')

                if(this.winWidth > this.winHeight) {
                    classes.push('media-image-view--' + 'album')
                } else {
                    classes.push('media-image-view--' + 'portrait')
                }
            }

            return {
                class: classes
            }
        }
    }
}
</script>

<style lang=scss>
    .media-image-view {
        img {
            max-width: 100%;
        }

        &--fullscreen {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            position: relative;

            .media-image-view__container {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 16px;
            }

            &.media-image-view--album {
                img {
                    max-height: 90%;
                }
            }

            &.media-image-view--portrait {
                img {
                    max-width: 90%;
                }
            }

        }
    }
</style>
